@import "src/assets/custom.scss";







































































































































#feedbackbox {
  position: fixed;
  width: 435px;
  padding: 32px;
  right: 20px;
  @include mobile {
    right: 0;
    max-width: 100%;
  }
  bottom: 20px;
z-index: 1000;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}

.protection {
  &.red {
    input {

      border-color: red !important;
    }

    label {
      color: red;

    }
  }
}

label {
  margin-bottom: 0;

  &.red {
    color: red;

  }
}

