$grid-gutter-width: 20px !default;
$grid-columns: 60 !default;

$orange: #DDA727;
$violett: #783683;
$gruen: #7FB754;
$blau: #6E889D;
/* open-sans-regular - latin */
$phonewidth: 768px;
$mobilewidth: 992px;
$tabletwidth: 1250px;
$laptopwidth: 1440px;

$grid-breakpoints: (
        xs: 0,
        sm: $phonewidth,
        md: $mobilewidth,
        lg: $tabletwidth,
        xl: $laptopwidth
);



$container-max-widths: (
        sm: 768px,
        md: 992px,
        lg: 1250px,
        xl: 1440px
);

$cubic: cubic-bezier(0.694, 0.0482, 0.335, 1);

@mixin mobile {
  @media (max-width: #{$mobilewidth}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin landscape {
  @media (max-width: "#{$phonewidth}") and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tabletwidth}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptopwidth}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: "#{$tabletwidth}") {
    @content;
  }
}

.page {

  .whitebox {
    width: 100%;
    font-size: 16px;
    padding: 50px;
    @include mobile {
      padding: 20px;
    }
    text-align: left;

    h1 {
    font-size: 36px;
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: 24px;
    margin: 10px 0;
  }
  h3 {
    font-size: 20px;

  }
}